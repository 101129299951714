import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { detailExpand } from "../../animations";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { GqlSearchService } from "../../services/gql-search.service";
import { LoaderService } from "../../services/loader.service";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "formly-collection-type",
  template: `
    <div *ngIf="initialized">
      <ion-list class="collection-list">
        <ion-item
          [routerLink]="['/' + path, collection.id]"
          *ngFor="let collection of collections"
        >
          <ion-icon
            class="smaller-icon"
            slot="start"
            [name]="collection.icon"
          ></ion-icon>
          <ion-label>{{
            meta.title_fields
              ? titleize(collection, meta.title_fields)
              : collection.name
          }}</ion-label>
        </ion-item>
      </ion-list>
      <div class="flex ion-align-items-center">
        <div class="h-spacer"></div>
        <ion-buttons>
          <ion-button
            tooltip="First page"
            hide-delay="0"
            [disabled]="page === 0"
            (click)="
              update({
                query: query,
                sort: sort,
                page: 0,
                itemsPerPage: itemsPerPage
              })
            "
            color="dark"
          >
            <ion-icon slot="icon-only" name="caret-back"></ion-icon>
          </ion-button>
          <ion-button
            tooltip="Previous page"
            hide-delay="0"
            [disabled]="page === 0"
            (click)="
              update({
                query: query,
                sort: sort,
                page: page - 1,
                itemsPerPage: itemsPerPage
              })
            "
            color="dark"
          >
            <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <span class="xs-text showing"
          >Showing {{ page * itemsPerPage + 1 }} -
          {{
            page * itemsPerPage + itemsPerPage > searchData.items
              ? searchData.items
              : page * itemsPerPage + itemsPerPage
          }}
          of {{ searchData.items }}</span
        >
        <ion-buttons>
          <ion-button
            tooltip="Next page"
            hide-delay="0"
            [disabled]="page >= searchData.pages"
            (click)="
              update({
                query: query,
                sort: sort,
                page: page + 1,
                itemsPerPage: itemsPerPage
              })
            "
            color="dark"
          >
            <ion-icon
              slot="icon-only"
              name="chevron-forward-outline"
            ></ion-icon>
          </ion-button>
          <ion-button
            tooltip="Last page"
            hide-delay="0"
            [disabled]="page >= searchData.pages"
            (click)="
              update({
                query: query,
                sort: sort,
                page: searchData.pages,
                itemsPerPage: itemsPerPage
              })
            "
            color="dark"
          >
            <ion-icon slot="icon-only" name="caret-forward"></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
    </div>
  `,
  animations: [detailExpand],
})
export class CollectionTypeComponent extends FieldType implements OnInit {
  query: string = "";
  collections: any[];
  meta: any;
  searchData: any;

  sorter = new BehaviorSubject(null);
  sort: string;

  page: number = 0;
  itemsPerPage: number = 20;
  initialized: boolean = false;

  schema: any;
  path: string;
  operator: string;

  constructor(
    private fs: AngularFirestore,
    private router: Router,
    private gql: GqlSearchService,
    private loader: LoaderService
  ) {
    super();
  }

  defaultOptions = {
    defaultValue: {},
  };
  async ngOnInit() {
    await this.loader.presentLoading({ msg: "Loading..." });
    const id = this.model.id;
    const src = this.model.source;
    const path = this.to.description;
    const spl = this.to.description.split(" ");
    this.path = spl[0];
    this.operator = spl[1] || "==";
    const schema = (
      await this.fs.doc(`collections_/${src}`).get().toPromise()
    ).data();
    this.schema = schema;
    const data = await this.gql.quicksearch({
      collection: this.path,
      where: {
        //@ts-ignore
        field: schema.schema,
        operator: this.operator,
        //@ts-ignore
        value: `${schema.id}/${id}`,
      },
    });
    //const res = (await this.fs.collection(path,ref => ref.where(schema.schema,'==',`${schema.id}/${id}`)).get().toPromise()).docs.map(doc => doc.data());
    this.collections = data.data;
    this.meta = data.meta;
    this.sort = `${data.meta.sort}_asc`;
    this.searchData = data.meta;
    this.initialized = true;
    this.loader.dismiss();
  }

  async update({ query, sort, page, itemsPerPage }) {
    await this.loader.presentLoading({ msg: "Loading..." });
    const id = this.model.id;
    const src = this.model.source;
    let s;
    if (sort) s = sort.split("_");
    const updData = await this.gql.quicksearch({
      collection: this.meta.id,
      where: {
        field: this.schema.schema,
        operator: this.operator,
        value: `${this.schema.id}/${id}`,
      },
      query: query,
      sort: s ? s[0] : "",
      dir: s ? s[1] : "",
      page: page,
      itemsPerPage: itemsPerPage,
    });
    this.page = page;
    this.collections = updData.data;
    this.searchData = updData.meta;
    await this.loader.dismiss();
  }
  titleize(item, fields) {
    if (fields && fields.length) return fields.map((f) => item[f]).join(" ");
    return item.name;
  }
}
