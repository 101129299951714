import { Directive, ElementRef, OnInit, Input } from "@angular/core";

@Directive({
  selector: "[appVertResizable]", // Attribute selector
})
export class VerticalResizableDirective implements OnInit {
  @Input() resizableGrabHeight = 2;
  @Input() resizableMinHeight = 4;

  dragging = false;

  constructor(private el: ElementRef) {
    const self = this;

    const EventListenerMode = { capture: true };

    function preventGlobalMouseEvents() {
      document.body.style["pointer-events"] = "none";
    }

    function restoreGlobalMouseEvents() {
      document.body.style["pointer-events"] = "auto";
    }

    const newHeight = (wid) => {
      const newHeight = Math.max(this.resizableMinHeight, wid);
      const y = this.el.nativeElement.getClientRects()[0].y;
      el.nativeElement.style.height = newHeight - y + "px";
    };

    const mouseMoveG = (evt) => {
      if (!this.dragging) {
        return;
      }
      newHeight(evt.clientY - el.nativeElement.offsetTop);
      evt.stopPropagation();
    };

    const dragMoveG = (evt) => {
      if (!this.dragging) {
        return;
      }
      const newHeight =
        Math.max(
          this.resizableMinHeight,
          evt.clientY - el.nativeElement.offsetTop
        ) + "px";
      el.nativeElement.style.height =
        evt.clientY - el.nativeElement.offsetTop + "px";
      evt.stopPropagation();
    };

    const mouseUpG = (evt) => {
      this.el.nativeElement.style["border-bottom"] =
        this.resizableGrabHeight + "px solid rgba(0,0,0,0.125)";

      if (!this.dragging) {
        return;
      }
      restoreGlobalMouseEvents();
      this.dragging = false;
      evt.stopPropagation();
    };

    const mouseDown = (evt) => {
      if (this.inDragRegion(evt)) {
        this.dragging = true;
        preventGlobalMouseEvents();
        evt.stopPropagation();
      }
    };

    const mouseMove = (evt) => {
      if (this.inDragRegion(evt) || this.dragging) {
        el.nativeElement.style.cursor = "row-resize";
        this.el.nativeElement.style["border-bottom"] =
          "4px solid var(--ion-color-primary)";
      } else {
        this.el.nativeElement.style["border-bottom"] =
          this.resizableGrabHeight + "px solid rgba(0,0,0,0.125)";
        el.nativeElement.style.cursor = "default";
      }
    };

    const enter = (evt) => {
      el.nativeElement.style["border-bottom"] = "8px solid rgba(0,0,0,0.125)";
    };
    const leave = (evt) => {
      if (!this.dragging)
        this.el.nativeElement.style["border-bottom"] =
          this.resizableGrabHeight + "px solid rgba(0,0,0,0.125)";
    };

    document.addEventListener("mouseleave", leave, true);
    document.addEventListener("mousemove", mouseMoveG, true);
    document.addEventListener("mouseup", mouseUpG, true);
    el.nativeElement.addEventListener("mousedown", mouseDown, true);
    el.nativeElement.addEventListener("mousemove", mouseMove, true);
  }

  ngOnInit(): void {
    this.el.nativeElement.style["border-bottom"] =
      this.resizableGrabHeight + "px solid rgba(0,0,0,0.125)";
  }

  inDragRegion(evt) {
    const y = this.el.nativeElement.getClientRects()[0].y;
    return (
      this.el.nativeElement.clientHeight +
        y -
        evt.clientY +
        this.el.nativeElement.offsetTop <
      this.resizableGrabHeight
    );
  }
}
