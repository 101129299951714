import { AnimationController } from "@ionic/angular";
import {
  state,
  transition,
  animate,
  style,
  trigger,
} from "@angular/animations";
const animationCtrl = new AnimationController();

const getIonPageElement = (element: HTMLElement) => {
  if (element.classList.contains("ion-page")) {
    return element;
  }

  const ionPage = element.querySelector(
    ":scope > .ion-page, :scope > ion-nav, :scope > ion-tabs"
  );
  if (ionPage) {
    return ionPage;
  }
  // idk, return the original element so at least something animates and we don't have a null pointer
  return element;
};

export const detailExpand = trigger("detailExpand", [
  state(
    "collapsed",
    style({ height: "0px", minHeight: "0", visibility: "hidden" })
  ),
  state("expanded", style({ height: "*", visibility: "visible" })),
  transition(
    "expanded <=> collapsed",
    animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
  ),
]);

export const inOut = trigger("inOut", [
  transition(":enter", [
    style({ height: "0px", minHeight: "0", visibility: "hidden" }),
    animate(
      "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
      style({ height: "*", visibility: "visible" })
    ),
  ]),
  transition(":leave", [
    style({ height: "*", visibility: "visible" }),
    animate(
      "225ms cubic-bezier(0.4, 0.0, 0.2, 1)",
      style({ height: "0px", minHeight: "0", visibility: "hidden" })
    ),
  ]),
]);

export function fadeAnimation(_: HTMLElement, opts: any) {
  const backDirection = opts.direction === "back";
  const enteringEl = opts.enteringEl;
  const leavingEl = opts.leavingEl;

  const enteringPageEl = getIonPageElement(enteringEl);

  const rootTransition = animationCtrl.create();

  const enterTransition = animationCtrl.create();
  const leavingTransition = animationCtrl.create();

  leavingTransition.addElement(getIonPageElement(leavingEl)).duration(250);

  enterTransition
    .addElement(enteringPageEl)
    .duration(250)
    .fill("both")
    .beforeRemoveClass("ion-page-invisible");

  if (!backDirection) {
    enterTransition
      //.beforeStyles({ border: 'thin solid black' })
      .keyframes([
        { offset: 0, opacity: 0 },
        { offset: 1, opacity: 1 },
      ]);
    //.afterClearStyles(['border']);

    leavingTransition.keyframes([
      { offset: 0, opacity: 1 },
      { offset: 1, opacity: 0.1 },
    ]);
  } else {
    enterTransition.keyframes([
      { offset: 0, opacity: 0.1 },
      { offset: 1, opacity: 1 },
    ]);

    leavingTransition
      //.beforeStyles({ border: 'thin solid black' })
      .keyframes([
        { offset: 0, opacity: 1 },
        { offset: 1, opacity: 0 },
      ]);
    //.afterClearStyles(['border']);
  }

  rootTransition.addAnimation([enterTransition, leavingTransition]);

  return rootTransition;
}
