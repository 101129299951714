import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import {
  SchemaResolver,
  CollectionResolver,
  DocResolver,
  APIResolver,
} from "./resolvers/resolver";
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from "@angular/fire/compat/auth-guard";
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["/auth"]);

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
    resolve: {
      data: CollectionResolver,
    },
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/auth/auth.module").then((m) => m.AuthPageModule),
  },
  {
    path: "tools/playground",
    resolve: {
      data: APIResolver,
    },
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./tools/playground/playground.module").then(
        (m) => m.PlaygroundPageModule
      ),
  },
  {
    path: "tools/processes",
    resolve: {
      data: CollectionResolver,
    },
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      collection: "processes_",
    },
    loadChildren: () =>
      import("./tools/processes/processes.module").then(
        (m) => m.ProcessesPageModule
      ),
  },
  {
    path: "tools/processes/:id",
    resolve: {
      collections: CollectionResolver,
      //apis: APIResolver
    },
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./tools/builder/builder.module").then((m) => m.BuilderPageModule),
  },
  {
    path: "tools/hotJar",
    resolve: {
      // collections: CollectionResolver,
      //apis: APIResolver
    },
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./tools/hotjar/hotjar.module").then((m) => m.HotJarPageModule),
  },
  {
    path: "schemas_/:schema",
    resolve: {
      data: SchemaResolver,
    },
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./pages/schema/schema.module").then((m) => m.SchemaPageModule),
  },
  {
    path: ":collection",
    resolve: {
      data: CollectionResolver,
    },
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./pages/collections/collections.module").then(
        (m) => m.CollectionsPageModule
      ),
  },
  {
    path: ":collection/:doc",
    resolve: {
      data: DocResolver,
    },
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./pages/doc/doc.module").then((m) => m.DocPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
