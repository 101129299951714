import { Injectable } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, of } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  userObs: Observable<any>;
  public loggedIn: boolean = false;

  constructor(
    private af: AngularFireAuth,
    private fs: AngularFirestore,
    private toastController: ToastController
  ) {
    this.userObs = this.af.authState.pipe(
      switchMap((user: any) => {
        if (user) {
          return fs.doc(`admins_/${user.uid}`).valueChanges();
        }
        this.loggedIn = false;
        return of(null);
      }),
      tap((user) => {
        if (user) this.loggedIn = true;
      })
    );
    this.userObs.subscribe();
  }
  async login(data) {
    const profile: any = data.authResult.additionalUserInfo.profile;
    const uid = data.authResult.user.uid;
    const email = data.authResult.user.email;
    const check = (
      await this.fs
        .collection(`admins_`, (ref) => ref.where("email", "==", email))
        .get()
        .toPromise()
    ).docs[0];
    if (check) {
      this.loggedIn = true;
      if (uid !== check.id) {
        let clone = check.data();
        //@ts-ignore
        clone.id = uid;
        //@ts-ignore
        clone.uid = uid;
        let batch = this.fs.firestore.batch();
        batch.set(this.fs.doc(`admins_/${uid}`).ref, clone);
        batch.delete(check.ref);
        await batch.commit();
      }
      return await this.presentToast(`Welcome Back, ${profile.givenName}`);
    }
    await this.af.signOut();
    return await this.presentToast(`User not registered`, "danger");
  }
  async logout() {
    const success = await this.af.signOut();
    await this.presentToast("You have been successfully logged out.", "danger");
    return success;
  }
  async presentToast(msg, color?) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: color || "primary",
      position: "top",
    });
    toast.present();
  }
}
