// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroller {
  height: 100%;
  overflow: auto;
}

.top-bar {
  padding-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/icon-modal/icon-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;AACF;;AACA;EACE,iBAAA;AAEF","sourcesContent":[".scroller {\n  height: 100%;\n  overflow: auto;\n}\n.top-bar {\n  padding-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
