import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "formly-object-type",
  template: `
    <ion-label position="stacked">{{ to.label }}</ion-label>
    <ion-item>
      <ion-input
        *ngIf="!disable; else mask"
        [formControl]="formControl"
      ></ion-input>
      <ng-template #mask>
        <ion-input disabled [value]="formControl.value"></ion-input>
      </ng-template>
      <ion-button
        *ngIf="!formControl.disabled && !disable"
        (click)="autoID()"
        size="small"
        color="primary"
        fill="outline"
      >
        <ion-label>Auto-ID</ion-label>
      </ion-button>
    </ion-item>
  `,
})
export class IdTypeComponent extends FieldType implements OnInit {
  disable: boolean = false;

  constructor(private fs: AngularFirestore) {
    super();
  }
  async ngOnInit() {
    //console.log(this)
    if (this.formControl.value) this.disable = true;
  }

  autoID() {
    this.formControl.setValue(this.fs.createId());
  }
}
