// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stick-bottom {
  position: sticky;
  bottom: 0;
  z-index: 999;
  background: rgba(var(--ion-background-color-rgb), 0.5);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  border-top: 1px solid rgba(var(--ion-color-dark-rgb), 0.25);
  opacity: 0;
  transform: translateY(-100px);
  visibility: hidden;
  transition: all 0.5s;
}

.stick-bottom.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/app/components/schema-form/schema-form.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,SAAA;EACA,YAAA;EACA,sDAAA;EACA,kCAAA;UAAA,0BAAA;EACA,2DAAA;EACA,UAAA;EACA,6BAAA;EACA,kBAAA;EACA,oBAAA;AACF;;AACA;EACE,UAAA;EACA,wBAAA;EACA,mBAAA;AAEF","sourcesContent":[".stick-bottom {\n  position: sticky;\n  bottom: 0;\n  z-index: 999;\n  background: rgba(var(--ion-background-color-rgb), 0.5);\n  backdrop-filter: blur(6px);\n  border-top: 1px solid rgba(var(--ion-color-dark-rgb), 0.25);\n  opacity: 0;\n  transform: translateY(-100px);\n  visibility: hidden;\n  transition: all 0.5s;\n}\n.stick-bottom.show {\n  opacity: 1;\n  transform: translateY(0);\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
