import { Component, OnInit } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { PopoverController } from "@ionic/angular";
import { FieldType } from "@ngx-formly/core";
import { ColorPickerComponent } from "../color-picker/color-picker.component";

@Component({
  selector: "formly-object-type",
  template: `
    <ion-item detail="false" button (click)="colorPicker($event)">
      <ion-label position="stacked" class="flex ion-align-items-center">
        {{ to.label }}
        <ion-icon
          name="ellipse"
          style="margin-left:1rem; font-size: 2rem"
          [ngStyle]="{
            color:
              'rgba(' +
              this.formControl.value.r +
              ',' +
              this.formControl.value.g +
              ',' +
              this.formControl.value.b +
              ',' +
              (this.formControl.value.a >= 0 ? this.formControl.value.a : 1) +
              ')'
          }"
        ></ion-icon>
      </ion-label>
    </ion-item>
  `,
})
export class ColorTypeComponent extends FieldType implements OnInit {
  constructor(
    private popoverController: PopoverController,
    private fns: AngularFireFunctions
  ) {
    super();
  }
  async ngOnInit() {}
  async colorPicker(ev: any) {
    const popover = await this.popoverController.create({
      component: ColorPickerComponent,
      cssClass: "color-picker-popover",
      event: ev,
      componentProps: {
        color: this.formControl.value || {},
      },
    });
    popover.onDidDismiss().then(({ data }) => {
      if (data) {
        this.formControl.setValue(data);
        this.form.markAsDirty();
      }
    });
    return await popover.present();
  }
}
