import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { detailExpand } from "src/app/animations";

@Component({
  selector: "app-color-picker",
  template: `
    <color-chrome
      [color]="color"
      (onChangeComplete)="handleChange($event)"
    ></color-chrome>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="dismiss()" color="dark"
          ><ion-label>Cancel</ion-label></ion-button
        >
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button (click)="dismiss(color)"
          ><ion-label>Done</ion-label></ion-button
        >
      </ion-buttons>
    </ion-toolbar>
  `,
  styleUrls: ["./color-picker.component.scss"],
  animations: [detailExpand],
})
export class ColorPickerComponent implements OnInit {
  constructor(private popoverController: PopoverController) {}

  color: any;

  ngOnInit() {}

  handleChange(e) {
    this.color = e.color.rgb;
  }
  dismiss(color?) {
    this.popoverController.dismiss(color || null);
  }
}
