import { Directive, ElementRef, OnInit, Input } from "@angular/core";

@Directive({
  selector: "[appResizable]", // Attribute selector
})
export class ResizableDirective implements OnInit {
  @Input() resizableGrabWidth = 2;
  @Input() resizableMinWidth = 4;

  dragging = false;

  constructor(private el: ElementRef) {
    const self = this;

    const EventListenerMode = { capture: true };

    function preventGlobalMouseEvents() {
      document.body.style["pointer-events"] = "none";
    }

    function restoreGlobalMouseEvents() {
      document.body.style["pointer-events"] = "auto";
    }

    const newWidth = (wid) => {
      const newWidth = Math.max(this.resizableMinWidth, wid);
      const x = el.nativeElement.getClientRects()[0].x;
      el.nativeElement.style.width = newWidth - x + "px";
    };

    const mouseMoveG = (evt) => {
      if (!this.dragging) {
        return;
      }
      newWidth(evt.clientX - el.nativeElement.offsetLeft);
      evt.stopPropagation();
    };

    const dragMoveG = (evt) => {
      if (!this.dragging) {
        return;
      }
      const newWidth =
        Math.max(
          this.resizableMinWidth,
          evt.clientX - el.nativeElement.offsetLeft
        ) + "px";
      el.nativeElement.style.width =
        evt.clientX - el.nativeElement.offsetLeft + "px";
      evt.stopPropagation();
    };

    const mouseUpG = (evt) => {
      this.el.nativeElement.style["border-right"] =
        this.resizableGrabWidth + "px solid rgba(0,0,0,0.125)";

      if (!this.dragging) {
        return;
      }
      restoreGlobalMouseEvents();
      this.dragging = false;
      evt.stopPropagation();
    };

    const mouseDown = (evt) => {
      if (this.inDragRegion(evt)) {
        this.dragging = true;
        preventGlobalMouseEvents();
        evt.stopPropagation();
      }
    };

    const mouseMove = (evt) => {
      if (this.inDragRegion(evt) || this.dragging) {
        el.nativeElement.style.cursor = "col-resize";
        this.el.nativeElement.style["border-right"] =
          "4px solid var(--ion-color-primary)";
      } else {
        this.el.nativeElement.style["border-right"] =
          this.resizableGrabWidth + "px solid rgba(0,0,0,0.125)";
        el.nativeElement.style.cursor = "default";
      }
    };

    const enter = (evt) => {
      el.nativeElement.style["border-right"] = "8px solid rgba(0,0,0,0.125)";
    };
    const leave = (evt) => {
      if (!this.dragging)
        this.el.nativeElement.style["border-right"] =
          this.resizableGrabWidth + "px solid rgba(0,0,0,0.125)";
    };

    document.addEventListener("mouseleave", leave, true);
    document.addEventListener("mousemove", mouseMoveG, true);
    document.addEventListener("mouseup", mouseUpG, true);
    el.nativeElement.addEventListener("mousedown", mouseDown, true);
    el.nativeElement.addEventListener("mousemove", mouseMove, true);
  }

  ngOnInit(): void {
    this.el.nativeElement.style["border-right"] =
      this.resizableGrabWidth + "px solid rgba(0,0,0,0.125)";
  }

  inDragRegion(evt) {
    const x = this.el.nativeElement.getClientRects()[0].x;
    return (
      this.el.nativeElement.clientWidth -
        evt.clientX +
        x +
        this.el.nativeElement.offsetLeft <
      this.resizableGrabWidth
    );
  }
}
