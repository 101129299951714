import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ResizableDirective } from "./resizable.directive";
import { VerticalResizableDirective } from "./vertical-resizable.directive";

@NgModule({
  declarations: [ResizableDirective, VerticalResizableDirective],
  imports: [CommonModule],
  exports: [ResizableDirective, VerticalResizableDirective],
})
export class DirectivesModule {}
