// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .bordered {
        border: 1px solid rgba(var(--ion-color-dark-rgb), 0);
        padding: 6px;
      }
      .bordered.show {
        border: 1px solid rgba(var(--ion-color-dark-rgb), 0.25);
      }
    `, "",{"version":3,"sources":["webpack://./src/app/components/types/sequential.type.ts"],"names":[],"mappings":";MACM;QACE,oDAAoD;QACpD,YAAY;MACd;MACA;QACE,uDAAuD;MACzD","sourcesContent":["\n      .bordered {\n        border: 1px solid rgba(var(--ion-color-dark-rgb), 0);\n        padding: 6px;\n      }\n      .bordered.show {\n        border: 1px solid rgba(var(--ion-color-dark-rgb), 0.25);\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
