import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { detailExpand } from "../../animations";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";

@Component({
  selector: "formly-object-type",
  template: `
    <div class="flex ion-align-items-center ion-padding-left">
      <ion-item class="h-spacer" *ngIf="opts && opts.length; else loading">
        <ion-label position="stacked">{{ to.label }}</ion-label>
        <ion-select
          [interfaceOptions]="{ cssClass: 'api-popover' }"
          [disabled]="form.disabled"
          [formControl]="formControl"
          interface="popover"
        >
          <ion-select-option *ngFor="let opt of opts" [value]="opt.ref">{{
            opt.name
          }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-chip
        color="primary"
        (click)="goTo(ref)"
        *ngIf="formControl.value as ref"
      >
        <ion-label>{{ ref }}</ion-label>
        <ion-icon name="link-outline"></ion-icon>
      </ion-chip>
      <ion-button
        *ngIf="formControl.value"
        size="small"
        color="danger"
        fill="clear"
        type="button"
        (click)="clear()"
      >
        <ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </div>
    <ng-template #loading>
      <ion-item class="h-spacer">
        <ion-label position="stacked">
          {{ to.label }}
        </ion-label>
        <ion-select disabled placeholder="loading..."></ion-select>
      </ion-item>
    </ng-template>
  `,
  animations: [detailExpand],
})
export class PickerTypeComponent extends FieldType implements OnInit {
  opts: any[];

  constructor(private fs: AngularFirestore, private router: Router) {
    super();
  }

  defaultOptions = {
    defaultValue: {},
  };
  async ngOnInit() {
    if (
      !this.formControl.value ||
      (typeof this.formControl.value === "object" &&
        !Object.keys(this.formControl.value).length)
    )
      this.formControl.patchValue(null);
    const path = this.to.description;
    const schema = (
      await this.fs.doc(`collections_/${path}`).get().toPromise()
    ).data();
    this.opts = (await this.fs.collection(path).get().toPromise()).docs
      .map((doc) => ({
        ref: doc.ref.path,
        name:
          //@ts-ignore
          schema.title_fields && schema.title_fields.length
            ? //@ts-ignore
              schema.title_fields.map((f) => doc.get(f)).join(" ")
            : doc.get("name"),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }
  setValue(val) {
    this.formControl.setValue(val);
  }
  goTo(ref) {
    this.router.navigate([`/${ref}`]);
  }
  clear() {
    this.formControl.setValue(null);
    this.form.markAsDirty();
  }
}
