import { Component, OnInit } from "@angular/core";
import { FieldArrayType } from "@ngx-formly/core";
import { detailExpand } from "../../animations";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";

@Component({
  selector: "formly-sequential-type",
  template: `
    <div>
      <legend *ngIf="to.label">{{ to.label }}</legend>

      <ng-container *ngIf="opts; else loading">
        <div *ngFor="let o of opts; let i = index" class="row ">
          <div class="flex ion-align-items-center">
            <span class="ion-padding">{{ i + 1 }}</span>
            <ion-item class="h-spacer">
              <ion-select
                [interfaceOptions]="{ cssClass: 'api-popover' }"
                [placeholder]="'Select Level ' + (i + 1)"
                [formControl]="field.fieldGroup[i].formControl"
                (ionChange)="updateValue($event.target.value, i)"
                interface="popover"
              >
                <ion-select-option
                  *ngFor="let opt of opts[i]"
                  [value]="opt.ref"
                >
                  <ion-label class="s-text">{{
                    opt.name | titlecase
                  }}</ion-label>
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-chip
              (click)="goTo(vals[i])"
              *ngIf="field.fieldGroup[i].formControl.value"
              color="primary"
            >
              <ion-label>{{ vals[i] }}</ion-label>
              <ion-icon name="link-outline"></ion-icon>
            </ion-chip>
            <ion-button
              *ngIf="field.fieldGroup[i].formControl.value"
              size="small"
              color="danger"
              fill="clear"
              type="button"
              (click)="removeOpt(i); remove(i)"
            >
              <ion-icon name="close-circle-outline"></ion-icon>
            </ion-button>
          </div>
        </div>
      </ng-container>
      <ng-template #loading>
        <div class="row ion-padding-start">
          <div class="flex ion-align-items-center">
            <ion-item class="h-spacer">
              <ion-select disabled placeholder="Loading..."
                ><ion-select-option>Loading...</ion-select-option></ion-select
              >
            </ion-item>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  animations: [detailExpand],
  styles: [
    `
      .bordered {
        border: 1px solid rgba(var(--ion-color-dark-rgb), 0);
        padding: 6px;
      }
      .bordered.show {
        border: 1px solid rgba(var(--ion-color-dark-rgb), 0.25);
      }
    `,
  ],
})
export class SequentialTypeComponent extends FieldArrayType implements OnInit {
  opts: any[];
  vals: any[] = [];
  all: any[];
  schema: any;
  path: string;

  constructor(private fs: AngularFirestore, private router: Router) {
    super();
  }

  defaultOptions = {
    defaultValue: {},
  };
  async ngOnInit() {
    this.vals = this.model || [];

    const path = this.to.description;
    this.path = path;
    const schema = (
      await this.fs.doc(`collections_/${path}`).get().toPromise()
    ).data();
    this.schema = schema;
    const all = (await this.fs.collection(path).get().toPromise()).docs.map(
      (doc) => doc.data()
    );

    let obj = {};
    all.forEach((a: any) => {
      obj[a.level] = obj[a.level] || [];
      obj[a.level].push(a);
    });

    let objArr = Object.keys(obj).map((k) => obj[k]);
    this.all = [...objArr];
    this.opts = objArr
      .map((a, i) =>
        a
          .map((b) => {
            b.ref = `${path}/${b.id}`;
            //b.name = schema.title_fields ? schema.title_fields.map(f => b[f]).join(' ') : b.name;
            return b;
          })
          .filter((c) => {
            if (i === 0) return true;
            if (this.vals[i - 1]) return c.parent === this.vals[i - 1];
            return false;
          })
      )
      .filter((f) => f.length);
    this.add();
    this.form.markAsPristine();

    //name: schema.title_fields ? schema.title_fields.map(f => doc.get(f)).join(' ') : doc.get('name')
  }

  async getPaths(i) {
    const path = this.to.description;
  }
  log() {
    console.log(this);
  }
  removeOpt(i) {
    this.opts.splice(i + 1, this.opts.length);
  }

  updateValue(val, i) {
    // console.log(i);
    const schema = this.schema;
    this.opts.forEach((e, j) => {
      if (j > i) {
        this.removeOpt(j);
        this.remove(j);
      }
    });

    if (!this.field.fieldGroup[i + 1]) this.add();

    //this.field.fieldGroup[i].formControl.setValue(val);
    const objArr = [...this.all];
    this.opts = objArr
      .map((a, i) =>
        a
          .map((b) => {
            b.ref = `${this.path}/${b.id}`;
            //b.name = schema.title_fields ? schema.title_fields.map(f => b[f]).join(' ') : b.name;
            return b;
          })
          .filter((c) => {
            if (i === 0) return true;
            if (this.vals[i - 1]) return c.parent === this.vals[i - 1];
            return false;
          })
      )
      .filter((f) => f.length);
    //this.formControl.setValue(val)
  }
  goTo(ref) {
    this.router.navigate([`/${ref}`]);
  }
}
