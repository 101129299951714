// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB1lWTkgIGtB2s25hKlPqqix7E5Ouu13bI",
    authDomain: "alcal-master.firebaseapp.com",
    databaseURL: "https://alcal-master.firebaseio.com",
    projectId: "alcal-master",
    storageBucket: "alcal-master.appspot.com",
    messagingSenderId: "273380147714",
    appId: "1:273380147714:web:5d11f7613e9c2f6dc8dd1b",
    measurementId: "G-2050N6LJW4",
  },
  editorAPI: "4yasythtaxfasrme8dq6wiqaowzlpt0t8ctwm4pm5uw3qw37",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
