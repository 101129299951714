import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { BehaviorSubject, Subject } from "rxjs";
import { map } from "rxjs/operators";

let cache = {};

@Injectable({
  providedIn: "root",
})
export class GqlSearchService {
  searchState: Subject<any> = new BehaviorSubject(null);
  searches: any = {};
  query: string;

  constructor(private apollo: Apollo) {}

  async cachebust() {
    await this.apollo.client.resetStore();
    return true;
  }

  quicksearch({
    query = "",
    collection = "",
    page = 0,
    itemsPerPage = 20,
    sort = "",
    dir = "asc",
    where = null,
  }: {
    query?: string;
    collection: string;
    page?: number;
    itemsPerPage?: number;
    sort?: string;
    dir?: string;
    where?: any;
  }) {
    const req = `
                {
                  quicksearch(
                    query:"${query}", 
                    collection:"${collection}", 
                    page:${page}, 
                    itemsPerPage:${itemsPerPage}, 
                    sort:"${sort}", 
                    dir:"${dir}", 
                    ${
                      where
                        ? `where:{
                      field: "${where.field}",
                      operator: "${where.operator}",
                      value: "${where.value}"
                    }`
                        : ""
                    }
                )
              }
                `;
    //console.log(req);
    return this.apollo
      .query({
        query: gql`
          ${req}
        `,
      })
      .pipe(map((result: any) => result.data.quicksearch))
      .toPromise();
  }

  /*[
              {
                name:"manufacturers",
                itemsPerPage:10,
                page:1
              },
              {
                name:"categories",
                itemsPerPage:30,
                page:1
              }
              {
                name:"products",
                itemsPerPage:10,
                page:1
                ifEmpty:["categories","manufacturers"]
              }
            ]
            */

  search(query, { collections }) {
    this.query = query;
    if (!query) return;
    const req = `
    {
      search(
        query:"${query}",
        
        collections:[
          ${collections
            .map(
              (c) => `{
           name: "${c.id}",
           itemsPerPage:10,
           page: 1 
          }`
            )
            .join("\n")}
        ]
    ) {
          query
          meta {
            ${collections
              .map(
                (c) => `${c.id} {
              count
              pages
              page
              showing
            }`
              )
              .join("\n")}
          }
          ${collections
            .map(
              (c) => `${c.id} {
            id
            ${
              c.title_fields && c.title_fields.length
                ? c.title_fields.join("\n")
                : "name"
            }
            matchLevel
          }`
            )
            .join("\n")}
      }
  }
    `;
    return this.apollo
      .query({
        query: gql`
          ${req}
        `,
      })
      .pipe(map((result: any) => result.data.search))
      .toPromise();
  }

  async facet(facet, facetValue) {
    return this.apollo
      .query({
        query: gql`
                  {
                    facet(
                        name:"${facet}",
                        value:"${facetValue}",
                        itemsPerPage:10,
                        page:1
                        ) {
                        name
                        meta {
                            ellapsed
                            count
                            pages
                            page
                            itemsPerPage
                            showing
                        }
                        products {
                            id
                            product {
                                id
                                manufacturer_name
                                manufacturer_part_number
                                hierarchies
                                hierarchy
                                rank
                                search_index
                            }
                        }
                    }
                } 
    `,
      })
      .pipe(map((result: any) => result.data.facet))
      .toPromise();
  }
}
