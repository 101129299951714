import { Component, OnInit, Input } from "@angular/core";
import { detailExpand } from "../../animations";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-icon-modal",
  templateUrl: "./icon-modal.component.html",
  styleUrls: ["./icon-modal.component.scss"],
  animations: [detailExpand],
})
export class IconModalComponent implements OnInit {
  @Input("iconPromise") iconPromise: Promise<any>;
  icons: any[];
  filtered: any[] = [];
  selected: any;
  searchStr: string = "";

  constructor(private modalController: ModalController) {}

  async ngOnInit() {
    this.icons = (await this.iconPromise).icons.filter((icn) =>
      icn.tags.includes("outline")
    );
    this.filtered = [...this.icons];
  }

  filter() {
    if (!this.searchStr) this.filtered = [...this.icons];
    const query = this.searchStr.toLowerCase().trim();
    this.filtered = [
      ...this.icons.filter((icon) => {
        return icon.name.includes(query) || icon.tags.join(" ").includes(query);
      }),
    ];
  }

  dismiss() {
    this.modalController.dismiss({
      icon: this.selected.name,
    });
  }
}
