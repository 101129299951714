import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import {
  FormlyFormOptions,
  FormlyFieldConfig,
  FormlyForm,
} from "@ngx-formly/core";
import { FormlyJsonschema } from "@ngx-formly/core/json-schema";

@Component({
  selector: "app-schema-form",
  templateUrl: "./schema-form.component.html",
  styleUrls: ["./schema-form.component.scss"],
})
export class SchemaFormComponent implements OnInit, OnChanges {
  @Input() dirty: boolean;
  @Input() handled: boolean;
  @Input() schema: any;
  @Input() model: any;
  @Output() saved = new EventEmitter();

  @ViewChild("formly", { static: true }) formly: FormlyForm;

  form: UntypedFormGroup;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];

  constructor(private formlyJsonschema: FormlyJsonschema) {}

  ngOnInit() {
    this.initialize();
  }
  ngOnChanges() {
    if (this.handled && this.form) {
      this.form.enable();
    } else {
      this.initialize();
    }
    if (this.dirty && this.form) {
      this.form.markAsDirty();
      this.dirty = false;
    }
  }

  initialize() {
    this.form = new UntypedFormGroup({});
    this.options = {};
    let fields = this.formlyJsonschema.toFieldConfig(this.schema);
    this.fields = [fields];
  }

  async save() {
    this.handled = false;
    const value = this.form.value;
    this.form.disable();
    await wait(500);
    this.saved.emit(value);
  }
}

const wait = (ms) => {
  return new Promise((resolve) => {
    //@ts-ignore
    setTimeout(() => resolve(), ms);
  });
};
