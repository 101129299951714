import { Component, OnDestroy, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "src/environments/environment";
import * as HTML from "html-parse-stringify";
import { EditorComponent } from "@tinymce/tinymce-angular";

const API: string = environment.editorAPI;

@Component({
  selector: "formly-object-type",
  template: `
    <div class="ion-padding"></div>
    <ion-label position="stacked">{{ to.label }}</ion-label>
    <div *ngIf="init">
      <editor
        class="editor"
        (onInit)="isReady()"
        [formControl]="formControl"
        [apiKey]="api"
        [init]="cfg"
      >
      </editor>
    </div>
    <div class="ion-padding"></div>
  `,
  styles: [
    `
      .constrain {
        display: flex;
        align-items: center;
        height: 100px;
        overflow: hidden;
      }
      editor {
        transition: all 0.5s;
      }
      ion-spinner {
        margin: auto;
      }
    `,
  ],
})
export class ContentTypeComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  api: String = API;
  disable: boolean = false;
  initial: string = "Content goes here...";
  //@ViewChild('editor',{static:true}) editor: EditorComponent;
  init: boolean = false;

  constructor(private fs: AngularFirestore) {
    super();
  }
  async ngOnInit() {
    this.formControl.setValue(this.formControl.value || "");
    await wait(2000);
    this.init = true;
  }
  ngOnDestroy() {
    this.init = false;
  }
  logify(e) {
    console.log(e);
  }
  getVal() {
    const val = this.formControl.value;
    const parse = HTML.default.parse(val);
    const unparse = HTML.default.stringify(parse);
  }
  isReady() {
    this.init = true;
    this.formControl.setValue(this.formControl.value || "");
  }

  cfg: any = {
    base_url: "/tinymce",
    height: 500,
    menubar: false,
    object_resizing: true,
    plugins: [
      "advlist autolink lists link image charmap print",
      "preview anchor searchreplace visualblocks code",
      "fullscreen insertdatetime media table paste",
      "table imagetools image media code save autoresize",
    ],
    toolbar: `undo redo | fontselect forecolor backcolor | formatselect | bold italic |
          alignleft aligncenter alignright alignjustify | 
          bullist numlist outdent indent | table | image media | code `,
    file_picker_types: "image",
    extended_valid_elements: "ion-button[*],ion-label,ion-icon[*]",
    automatic_uploads: false,
    image_advtab: true,

    /*file_picker_callback: function (cb, value, meta) {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          const tinymce = this.editorManager;
    
          // Note: In modern browsers input[type="file"] is functional without 
          // even adding it to the DOM, but that might not be the case in some older
          // or quirky browsers like IE, so you might want to add it to the DOM
          // just in case, and visually hide it. And do not forget do remove it
          // once you do not need it anymore.
    
          input.onchange = function (s) {
            var file = input.files[0];
            var reader: any = new FileReader();
            reader.onload = function (e) {
              // Note: Now we need to register the blob in TinyMCEs image blob
              // registry. In the next release this part hopefully won't be
              // necessary, as we are looking to handle it internally.
              var id = 'blobId' + (new Date()).getTime();
              //console.log(tinymce)
    
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(',')[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
    
              // call the callback and populate the Title field with the file name
              cb(blobInfo.blobUri(), { title: id });
            };
            reader.readAsDataURL(file);
          };
    
          input.click();
        },*/
    /*images_upload_handler: async (blobInfo, success, failure) => {
          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(`assets/${blobInfo.filename()}`);
          const file = new File([blobInfo.blob()], blobInfo.filename());
          const ft = blobInfo.filename().split('.')[1];
          try {
            const snapshot = await fileRef.put(file, { 'contentType': `image/${ft}` })
            const url = await fileRef.getDownloadURL();
            //console.log(url)
            success(url)
            return
          }
          catch (e) {
            failure(e);
            return
          }
    
        }*/
  };
}
// @ts-ignore
const wait = (ms) => new Promise((resolve) => setTimeout(() => resolve(), ms));
