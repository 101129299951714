import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  loading: HTMLIonLoadingElement;

  constructor(private loadingController: LoadingController) {}

  async presentLoading({
    msg,
    cssClass,
  }: {
    msg: string;
    cssClass?: string | string[];
  }) {
    const loading =
      this.loading ||
      (await this.loadingController.create({
        cssClass: cssClass,
        message: msg,
      }));
    await loading.present();
    this.loading = loading;
    return true;
  }

  dismiss() {
    if (this.loading) this.loading.dismiss();
    this.loading = null;
  }
}
