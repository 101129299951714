// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .constrain {
        display: flex;
        align-items: center;
        height: 100px;
        overflow: hidden;
      }
      editor {
        transition: all 0.5s;
      }
      ion-spinner {
        margin: auto;
      }
    `, "",{"version":3,"sources":["webpack://./src/app/components/types/content.type.ts"],"names":[],"mappings":";MACM;QACE,aAAa;QACb,mBAAmB;QACnB,aAAa;QACb,gBAAgB;MAClB;MACA;QACE,oBAAoB;MACtB;MACA;QACE,YAAY;MACd","sourcesContent":["\n      .constrain {\n        display: flex;\n        align-items: center;\n        height: 100px;\n        overflow: hidden;\n      }\n      editor {\n        transition: all 0.5s;\n      }\n      ion-spinner {\n        margin: auto;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
