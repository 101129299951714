// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .label-div {
        padding-top: 10px;
        font-size: 14px;
      }
      ion-input {
        font-size: 14px;
        --padding-bottom: 0;
      }
      ion-button {
        position: relative;
      }
      ion-button ion-label {
        position: relative;
        z-index: 0;
      }
      ion-button input {
        position: absolute;
        top: 0;
        left: -16px;
        width: calc(100% + 32px);
        height: 100%;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
      }
      .remove-img {
        position: absolute;
        top: -6px;
        right: -6px;
        z-index: 1;
        cursor: pointer;
      }
      .inset {
        width: 18px;
      }
      .message {
        display: none;
        font-size: 14px;
        padding-bottom: 8px;
      }
      ion-thumbnail,
      .thumb {
        position: relative;
        height: 36px;
        width: 36px;
        margin-right: 16px;
      }
      .thumb {
        background: var(--ion-color-light);
      }
      div.invalid .message {
        display: block;
      }
      div.invalid .divider {
        background-color: var(--ion-color-danger);
      }
    `, "",{"version":3,"sources":["webpack://./src/app/components/types/image.type.ts"],"names":[],"mappings":";MACM;QACE,iBAAiB;QACjB,eAAe;MACjB;MACA;QACE,eAAe;QACf,mBAAmB;MACrB;MACA;QACE,kBAAkB;MACpB;MACA;QACE,kBAAkB;QAClB,UAAU;MACZ;MACA;QACE,kBAAkB;QAClB,MAAM;QACN,WAAW;QACX,wBAAwB;QACxB,YAAY;QACZ,UAAU;QACV,UAAU;QACV,eAAe;MACjB;MACA;QACE,kBAAkB;QAClB,SAAS;QACT,WAAW;QACX,UAAU;QACV,eAAe;MACjB;MACA;QACE,WAAW;MACb;MACA;QACE,aAAa;QACb,eAAe;QACf,mBAAmB;MACrB;MACA;;QAEE,kBAAkB;QAClB,YAAY;QACZ,WAAW;QACX,kBAAkB;MACpB;MACA;QACE,kCAAkC;MACpC;MACA;QACE,cAAc;MAChB;MACA;QACE,yCAAyC;MAC3C","sourcesContent":["\n      .label-div {\n        padding-top: 10px;\n        font-size: 14px;\n      }\n      ion-input {\n        font-size: 14px;\n        --padding-bottom: 0;\n      }\n      ion-button {\n        position: relative;\n      }\n      ion-button ion-label {\n        position: relative;\n        z-index: 0;\n      }\n      ion-button input {\n        position: absolute;\n        top: 0;\n        left: -16px;\n        width: calc(100% + 32px);\n        height: 100%;\n        opacity: 0;\n        z-index: 1;\n        cursor: pointer;\n      }\n      .remove-img {\n        position: absolute;\n        top: -6px;\n        right: -6px;\n        z-index: 1;\n        cursor: pointer;\n      }\n      .inset {\n        width: 18px;\n      }\n      .message {\n        display: none;\n        font-size: 14px;\n        padding-bottom: 8px;\n      }\n      ion-thumbnail,\n      .thumb {\n        position: relative;\n        height: 36px;\n        width: 36px;\n        margin-right: 16px;\n      }\n      .thumb {\n        background: var(--ion-color-light);\n      }\n      div.invalid .message {\n        display: block;\n      }\n      div.invalid .divider {\n        background-color: var(--ion-color-danger);\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
