import { Component, OnInit, ViewChild } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { HttpClient } from "@angular/common/http";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { IonInput } from "@ionic/angular";

@Component({
  selector: "formly-field-image",
  template: `
    <div class="label-div">
      <ion-label>{{ to.label }}</ion-label>
    </div>
    <div class="flex ion-align-items-center">
      <div class="thumb flex ion-align-items-center" *ngIf="!url">
        <ion-icon color="medium" class="margin-auto" name="image"></ion-icon>
      </div>
      <ion-thumbnail *ngIf="url">
        <ion-icon
          (click)="clear()"
          class="remove-img"
          name="close-circle"
        ></ion-icon>
        <ion-img [src]="url"></ion-img>
      </ion-thumbnail>
      <ion-input
        [disabled]="form.disabled"
        #txt
        type="url"
        [pattern]="pattern"
        (ionChange)="handleUrl($event.target.value)"
        [placeholder]="placeholder"
      ></ion-input>
      <ion-button [disabled]="form.disabled">
        <ion-label>Upload From Desktop</ion-label>
        <input
          [disabled]="form.disabled"
          (change)="handleFile($event)"
          type="file"
          accept="image/*"
        />
      </ion-button>
    </div>
    <div [class.invalid]="invalid">
      <div class="divider"></div>
      <div class="message">
        <ion-text color="danger">Please enter a valid url</ion-text>
      </div>
    </div>
  `,
  styles: [
    `
      .label-div {
        padding-top: 10px;
        font-size: 14px;
      }
      ion-input {
        font-size: 14px;
        --padding-bottom: 0;
      }
      ion-button {
        position: relative;
      }
      ion-button ion-label {
        position: relative;
        z-index: 0;
      }
      ion-button input {
        position: absolute;
        top: 0;
        left: -16px;
        width: calc(100% + 32px);
        height: 100%;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
      }
      .remove-img {
        position: absolute;
        top: -6px;
        right: -6px;
        z-index: 1;
        cursor: pointer;
      }
      .inset {
        width: 18px;
      }
      .message {
        display: none;
        font-size: 14px;
        padding-bottom: 8px;
      }
      ion-thumbnail,
      .thumb {
        position: relative;
        height: 36px;
        width: 36px;
        margin-right: 16px;
      }
      .thumb {
        background: var(--ion-color-light);
      }
      div.invalid .message {
        display: block;
      }
      div.invalid .divider {
        background-color: var(--ion-color-danger);
      }
    `,
  ],
})
export class ImageTypeComponent extends FieldType implements OnInit {
  @ViewChild("txt", { static: true }) txt: IonInput;
  pattern: RegExp =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  url: string;
  invalid: boolean = false;
  placeholder: string = "Paste Image URL";

  constructor(private fns: AngularFireFunctions) {
    super();
  }

  ngOnInit() {
    if (this.formControl && this.formControl.value) {
      this.url = this.formControl.value.startsWith("data:")
        ? this.formControl.value
        : this.cacheBust(this.formControl.value);
    }
  }

  cacheBust(url) {
    return `${url}?t=${new Date().getTime()}`;
  }

  clear() {
    this.url = null;
    this.formControl.setValue(null);
    this.txt.value = "";
    this.formControl.markAsDirty();
  }

  async handleUrl(val) {
    val = val ? val.trim() : "";
    const pass = this.pattern.test(val);
    this.placeholder = "Paste Image URL";
    if (val && !pass) {
      this.invalid = true;
      this.url = null;
      this.formControl.setErrors({ invalid: true });
    } else if (pass) {
      this.invalid = false;
      const res = await this.getB64(val);
      if (res.error) {
        this.invalid = true;
      } else {
        this.url = res.b64;
        this.formControl.setValue(res.b64);
      }
      this.formControl.markAsDirty();
    } else {
      this.invalid = false;
      this.formControl.setValue(val);
      this.formControl.markAsPristine();
    }
  }
  handleFile(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      this.txt.value = "";
      setTimeout(() => {
        this.url = `${reader.result}`;
        this.formControl.setValue(`${reader.result}`);
        this.formControl.markAsDirty();
        this.placeholder = file.name;
      });
    };
    reader.readAsDataURL(file);
  }

  async getB64(url) {
    const fn = this.fns.httpsCallable("getB64");
    const res = await fn({ url: url }).toPromise();
    return res;
  }
}
