import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { GqlSearchService } from "../services/gql-search.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { LoaderService } from "../services/loader.service";

@Injectable({ providedIn: "root" })
export class SearchResolver {
  constructor(private service: GqlSearchService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const facet = route.paramMap.get("facet");
    const facetValue = route.paramMap.get("facetValue").toUpperCase();
    return this.service.facet(facet, facetValue);
  }
}

@Injectable({ providedIn: "root" })
export class ProductResolver {
  constructor(private fs: AngularFirestore) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const id = route.paramMap.get("productID");
    return this.getProduct(id);
  }

  getProduct = async (id) => {
    const product = (
      await this.fs.doc(`products/${id}`).get().toPromise()
    ).data();
    const schema = (
      await this.fs.doc(`schemas_/product`).get().toPromise()
    ).data();
    return {
      product: product,
      schema: schema,
    };
  };
}

@Injectable({ providedIn: "root" })
export class SchemaResolver {
  constructor(private fs: AngularFirestore, private loader: LoaderService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.getSchema(route.paramMap.get("schema"));
  }

  getSchema = async (schema) => {
    if (schema === "new") return {};
    await this.loader.presentLoading({ msg: "Loading..." });
    const doc = (
      await this.fs.doc(`schemas_/${schema}`).get().toPromise()
    ).data();
    let collection = {};
    let collectionSchema = {};
    //@ts-ignore
    if (doc.collection) {
      collection = (
        await this.fs
          .doc(
            //@ts-ignore
            `collections_/${doc.collection}`
          )
          .get()
          .toPromise()
      ).data();
      collectionSchema = JSON.parse(
        (await this.fs.doc(`schemas_/collection`).get().toPromise()).get("json")
      );
    }
    await this.loader.dismiss();
    return {
      //@ts-ignore
      schema: JSON.parse(doc.json),
      collection: collection,
      collectionSchema: collectionSchema,
    };
  };
}

@Injectable({ providedIn: "root" })
export class CollectionResolver {
  constructor(
    private fs: AngularFirestore,
    private service: GqlSearchService,
    private loader: LoaderService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.getCollection(
      route.paramMap.get("collection") ||
        route.data.collection ||
        "collections_"
    );
  }

  getCollection = async (collection) => {
    await this.loader.presentLoading({ msg: "Loading..." });
    const result = await this.service.quicksearch({
      collection: collection,
    });
    const schema = (
      await this.fs.doc(`schemas_/${result.meta.schema}`).get().toPromise()
    ).data();
    return {
      data: result.data,
      meta: result.meta,
      schema: schema,
    };
  };
}

@Injectable({ providedIn: "root" })
export class DocResolver {
  constructor(private fs: AngularFirestore, private loader: LoaderService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const collection = route.paramMap.get("collection");
    const id = route.paramMap.get("doc");
    return this.getDoc(collection, id);
  }

  getDoc = async (collection, id) => {
    await this.loader.presentLoading({ msg: "Loading..." });
    const col = (
      await this.fs.doc(`collections_/${collection}`).get().toPromise()
    ).data();
    const doc =
      id === "new"
        ? {}
        : (await this.fs.doc(`${collection}/${id}`).get().toPromise()).data();
    const schema = (
      await this.fs
        .doc(
          //@ts-ignore
          `schemas_/${col.schema}`
        )
        .get()
        .toPromise()
    ).data();
    await this.loader.dismiss();
    return {
      doc: doc,
      collection: col,
      schema: schema,
    };
  };
}

@Injectable({ providedIn: "root" })
export class APIResolver {
  constructor(private fs: AngularFirestore, private loader: LoaderService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.getAPIs();
  }

  getAPIs = async () => {
    await this.loader.presentLoading({ msg: "Loading..." });
    const apis = (await this.fs.collection(`apis_`).get().toPromise()).docs.map(
      (doc) => doc.data()
    );
    const schema = (await this.fs.doc(`schemas_/apis`).get().toPromise()).get(
      "json"
    );
    await this.loader.dismiss();
    return {
      apis: apis,
      schema: JSON.parse(schema),
    };
  };
}
