import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import * as electron from "ngx-electronyzer";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { environment } from "../environments/environment";
import { HttpClientModule } from "@angular/common/http";

import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";
import { ComponentsModule } from "./components/components.module";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyIonicModule } from "@ngx-formly/ionic";
import { DirectivesModule } from "./directives/directives.module";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
/*import {FirebaseUIModule, firebase, firebaseui} from 'firebase/compatui-angular';
const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: 'microsoft.com',
      loginHintKey: 'login_hint'
    }
  ]
};*/

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: "ios",
    }),
    AppRoutingModule,
    // NgxElectronModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    //FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    HttpClientModule,
    BrowserAnimationsModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyIonicModule,
    DirectivesModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: ORIGIN, useValue: ORIGIN },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            // uri: "http://localhost:5000/alcal-master/us-central1/graphql",
            uri: "https://us-central1-alcal-master.cloudfunctions.net/graphql",
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// Testing Use
//  uri: "http://localhost:5000/alcal-master/us-central1/graphql",
