import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class SchemaService {
  current: string;

  constructor(
    private fs: AngularFirestore,
    private toastController: ToastController
  ) {}

  saveSchema = async (tgt, schema) => {
    const json = JSON.stringify(schema);
    try {
      await this.fs.doc(`schemas_/${tgt}`).set({
        id: tgt,
        name: schema.title,
        icon: schema.icon,
        json: json,
      });
      const collection = (
        await this.fs.doc(`collections_/${schema.collection}`).get().toPromise()
      ).data();
      if (schema.collection && !collection)
        await this.fs.doc(`collections_/${schema.collection}`).set({
          id: schema.collection,
          name: schema.title,
          icon: schema.icon,
          schema: tgt,
        });
      await this.presentToast(true);
      return JSON.parse(json);
    } catch (e) {
      console.log(e);
      await this.presentToast(false);
      return null;
    }
  };

  async presentToast(success) {
    const toast = await this.toastController.create({
      message: success ? "Schema has been saved." : "Schema not saved.",
      duration: 2500,
      color: success ? "primary" : "danger",
      position: "top",
    });
    toast.present();
  }
}
