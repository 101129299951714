import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SchemaFormComponent } from "./schema-form/schema-form.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CodemirrorModule } from "@ctrl/ngx-codemirror";

import { FormlyModule } from "@ngx-formly/core";
import { FormlyIonicModule } from "@ngx-formly/ionic";
import { ArrayTypeComponent } from "./types/array.type";
import { NullTypeComponent } from "./types/null.type";
import { ObjectTypeComponent } from "./types/object.type";
import { MultiSchemaTypeComponent } from "./types/multischema.type";
import { IonicModule } from "@ionic/angular";
import { IconModalComponent } from "./icon-modal/icon-modal.component";
import { ImageTypeComponent } from "./types/image.type";
import { HttpClientModule } from "@angular/common/http";
import { PickerTypeComponent } from "./types/picker.type";
import { SequentialTypeComponent } from "./types/sequential.type";
import { CodeTypeComponent } from "./types/code.type";
import { IdTypeComponent } from "./types/id.type";
import { CollectionTypeComponent } from "./types/collection.type";
import { RouterModule } from "@angular/router";
import { DependantPickerTypeComponent } from "./types/dependant-picker.type";
import { ContentTypeComponent } from "./types/content.type";
import { ColorPickerComponent } from "./color-picker/color-picker.component";
import { ColorTypeComponent } from "./types/color.type";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";

import { ColorSketchModule } from "ngx-color/sketch";
import { ColorChromeModule } from "ngx-color/chrome";

@NgModule({
  declarations: [
    ColorPickerComponent,
    SchemaFormComponent,
    ArrayTypeComponent,
    NullTypeComponent,
    ObjectTypeComponent,
    MultiSchemaTypeComponent,
    IconModalComponent,
    ImageTypeComponent,
    PickerTypeComponent,
    DependantPickerTypeComponent,
    SequentialTypeComponent,
    CodeTypeComponent,
    CollectionTypeComponent,
    IdTypeComponent,
    ContentTypeComponent,
    ColorTypeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule,
    CodemirrorModule,
    FormlyIonicModule,
    HttpClientModule,
    EditorModule,
    ColorSketchModule,
    ColorChromeModule,
    FormlyModule.forRoot({
      validationMessages: [
        { name: "required", message: "This field is required" },
      ],
      types: [
        { name: "string", extends: "input" },
        {
          name: "number",
          extends: "input",
          defaultOptions: {
            templateOptions: {
              type: "string",
            },
          },
        },
        {
          name: "integer",
          extends: "input",
          defaultOptions: {
            templateOptions: {
              type: "string",
            },
          },
        },
        { name: "boolean", extends: "checkbox" },
        { name: "enum", extends: "select" },
        {
          name: "null",
          component: NullTypeComponent,
          wrappers: ["form-field"],
        },
        { name: "image", component: ImageTypeComponent },
        { name: "array", component: ArrayTypeComponent },
        { name: "object", component: ObjectTypeComponent },
        { name: "picker", component: PickerTypeComponent },
        { name: "dependant", component: DependantPickerTypeComponent },
        { name: "sequential", component: SequentialTypeComponent },
        { name: "multischema", component: MultiSchemaTypeComponent },
        { name: "code", component: CodeTypeComponent },
        { name: "collection", component: CollectionTypeComponent },
        { name: "id", component: IdTypeComponent },
        { name: "content", component: ContentTypeComponent },
        { name: "color", component: ColorTypeComponent },
      ],
    }),
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
  ],
  exports: [SchemaFormComponent, IconModalComponent],
})
export class ComponentsModule {}
