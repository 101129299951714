import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { detailExpand } from "../../animations";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "formly-object-type",
  template: `
    <ion-item>
      <ion-label position="stacked">{{ to.label }}</ion-label>
    </ion-item>
    <ngx-codemirror
      (keyup)="setValue(code)"
      (change)="setValue(code)"
      [(ngModel)]="code"
      [options]="{
        lineNumbers: true,
        theme: 'material',
        mode: 'javascript'
      }"
    ></ngx-codemirror>
  `,
  animations: [detailExpand],
})
export class CodeTypeComponent extends FieldType implements OnInit {
  code: string = "";

  constructor(private fs: AngularFirestore) {
    super();
    this.code = "";
  }
  async ngOnInit() {
    this.code = this.formControl.value || "";
  }
  setValue(val) {
    this.formControl.setValue(val);
    this.formControl.markAsDirty();
  }
}
